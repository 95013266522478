.steps {
  display: flex;
  flex-wrap: wrap;
  font-family: "Helvetica Neue", Helvetica, Arial;
  font-weight: 800;
  color: #f3f3f3;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 3px 4px;

  .step {
    margin: 2px;
    height: 22px;
    line-height: 30px;
    border-radius: 0;
    background: $secondary-bg;
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    text-shadow: rgba(0,0,0,.3) 1px 1px 0;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;

    .number {
      padding: 0 4px 0 4px;
      display: inline-block;
      font-size: 12px;
    }

    .divider {
      position: relative;
      margin-bottom: -18px;
      bottom: 9px;
      display: inline-block;
    }

    .tier-status {
      display: inline-block;
      padding: 0 5px;
      font-size: 12px;
    }
  }

  .done {
    background: #27a744;
  }

  .active {
    background: $primary-bg;
  }
}

.addons-actions {
  display: flex;
  justify-content: flex-end;

  input {
    width: 40px !important;
    margin: 0 10px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.addons {
  width: 100%;
  color: $coal;
  margin: 0 15px;
  .header {
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 25px;
    .max-addons {
      display: flex;
      font-size: 0.8rem;
      padding: 0 5px;
    }

    .free-addons {
      display: flex;
      font-size: 0.8rem;
      padding: 0 10px;
    }
    p {
      margin: 0;
      font-weight: 400;
      color: black;
    }

    strong {
      color: black;
    }

    .title {
      padding: 10px 25px;
      text-transform: capitalize;
    }
  }

  .list-group-item {
    padding: 5px 1.25rem;
  }
  .selected-addons {
    display: flex;
    flex-wrap: wrap;
    padding: 3px 2px;
    min-height: 74px;
    p {
      width: 100%;
      padding: 0 5px;
      margin: 0;
    }
    .addons-tag {
      color: black;
      font-size: 1rem;
      font-weight: 500;
    }
    .selected {
      background: $cloud;
      display: flex;
      margin: 3px 4px;
      align-items: center;
      padding: 0;
      font-size: 0.8rem;
      p {
      }
      span {
        display: flex;
        text-align: center;
        width: 39px;
        border: 1px solid #aaaaaa;
        padding: 4px 14px;
        font-size: 1rem;
        font-weight: 700;
        button {
          border: none;
          color: $primary-font-color;
          font-weight: 900;
          cursor: pointer;
        }
        input {
        }
        .add {
          background: $success;
        }
        .remove {
          background: $error;
        }
      }

      .delete {
        background: $primary-color;
        color: $primary-font-color;
        border: none;
        font-weight: 900;
        cursor: pointer;
        padding: 8px 16px;
      }
    }
  }
  .body {
    .addon {
      border-bottom: 1px solid $cloud;
      display: flex;
      align-items: center;
      padding: 1px 8px 1px 25px;
      p {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
      }
      button {
        color: $primary-font-color;
        background: $secondary-color;
        border: none;
        height: 30px;
        width: 40px;
        cursor: pointer;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }
}

.tier {
  display: flex;
  padding: 1px 0;

  .tier-title {
    font-size: 0.8rem;
    font-weight: 700;
    padding-right: 8px;
  }

  .tier-value {
    font-size: 0.8rem;
    padding: 0 0;
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    li {
      font-size: 0.65rem;
    }
  }
}

.hold-title {
  width: 100%;
  font-size: 1rem;
}
.hold-items {
  background: white;
  padding: 5px 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .items {
    display: flex;
    flex-wrap: wrap;
    .item {
      border: 2px solid #c8c8c8;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      margin-left: 5px;
      font-size: 1rem;
      margin-bottom: 10px;
      p {
        margin: 0;
        color: black;
      }
    }
    .active {
      border-color: $active;
      background: #efefef;
      p {
        color: $active;
      }
    }
  }
}
