

.summary {
  padding: 0;
  width: 100%;
  float: right;

  h3 {
    font-size: 1.3rem;
    font-weight: 900;
  }
  .row {
    width: 100%;
    background: #efefef;
    margin: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      width: 70%;
      color: black;
      font-size: 1rem;
      padding: 5px 10px;
      font-weight: 500;
    }
    .right {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        margin: 0;
        color: black;
        font-size: 1rem;
        padding: 5px 10px;
        font-weight: 700;
      }
    }
  }
}
.response {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 57vh;
  flex-direction: column;

  label {
    font-size: 1.4rem;
  }

  input {
    /* Double-sized Checkboxes */
    -ms-transform: scale(4); /* IE */
    -moz-transform: scale(4); /* FF */
    -webkit-transform: scale(4); /* Safari and Chrome */
    -o-transform: scale(4); /* Opera */
    transform: scale(4);
    padding: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    margin-left: -45px;
  }
}

.action-button {
  padding: 10px;
  width: 170px;
  height: 60px;
}
.customer-container {
  .btn-primary:hover {
    background: #d61a0d;
    color: white;
  }
}

.checkout-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 75vh;

  hr {
    width: 100%;
  }


  .terminals {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex: none;
    p {
      width: 100%;
    }
    .active {
      background: #00b9e4 !important;
    }
  }
}

.terminal-button {
  color: #444444;
  height: 60px;
  border-radius: 0;
  font-size: 15px;
  font-weight: bold;
  padding: 0 25px;
  margin: 5px 15px 0 0;
  border: 1px solid #ababab;
}

.numpad-wrapper {
  width: 320px;
  height: 382px;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;

  .numpad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .numpad-display {
      font-family: monospace;
      color: #715e94;
      background: white;
      line-height: 80px;
      font-size: 4em;
      height: 61px;
      text-align: center;
      width: 97%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #7700b3;
      font-weight: bold;
      letter-spacing: -5px;
      border-radius: 5px;

      .selected {
        background: #cb87ec;
        padding: 5px 15px 2px;
        font-size: 2.8rem;
      }
    }


    .auto-scaling-text {

    }

    .numpad-keypad {
      height: 326px;
      display: flex;
    }

    .input-keys {
      width: 240px;
    }

    .function-keys {
      display: flex;

      .numpad-key {
        font-size: 2em;
        font-family: monospace;
        font-weight: 600;
      }

      .key-multiply {
        line-height: 50px;
      }
    }
    .digit-keys {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;

      .numpad-key {
        font-size: 2.25em;
        font-family: monospace;
        font-weight: 600;
        color: #515156 !important
      }

      .key-0 {
        width: 152px;
        text-align: left;
        padding-left: 32px;
      }

      .key-dot {
        padding-top: 1em;
        font-size: 0.75em;
      }
    }

    .numpad-key {
      width: 73px;
      height: 75px;
      text-align: center;
      line-height: 80px;
      color: white;
      font-size: 30px;
      font-weight: 600;

      .numpad-key {
        border-right: 0;
        font-size: 3em;
        font-family: monospace;
        font-weight: 600;
        color: black !important;
      }
    }

    .function-keys {
      background: linear-gradient(to bottom, rgba(202,202,204,1) 0%, rgba(196,194,204,1) 100%);
    }

    .operator-keys {
      display: flex;
      flex-direction: column;
      button{
        background:  #777;
      }
    }

    button {
      margin: 3px;
      display: block;
      border: none;
      border-radius: 5px;
      padding: 0;
      font-family: inherit;
      user-select: none;
      cursor: pointer;
      outline: none;

      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    button:active {
      box-shadow: inset 0px 0px 80px 0px rgba(0,0,0,0.25);
    }
  }
}

.voucher {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #777;
  background: transparent;
}

.payment-dashboard {

  .total-amount {
    font-weight: 600;
    font-size: 1.35rem;
    margin-bottom: 0;
    width: 100%;
  }

  .payment-summary {
    width: 100%;
    background: #efefef;
    margin: 5px 4px;
    padding: 0 15px;
    height: 250px;
    overflow-y: scroll;
  }

  .payment-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    padding: 7px 0;
    .left  {
      font-size: 1.15rem;
      width: 70%;
      text-align: center;
    }
    .right {
      width: 30%;
      font-size: 1.6rem;
      text-align: center;
    }

  }

  .action-button {
    width: 120px;
    border-radius: 0;
    margin: 2px 2px;
    height: 96px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    border: none;
    padding: 15px 10px;

    i {
      font-size: 1.3rem;
    }
    span {
      font-size: 1.2rem;
      line-height: 22px;
      margin-top: 8px;
      font-weight: 600;
    }
  }
}


.change {
  font-size: 2rem;
  margin-top: 5px;
  font-weight: bold;

  .price {
    font-size: 3rem;
  }
}

