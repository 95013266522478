.update-booking {
  p {
    color: black !important;
    margin: 0 !important;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .status-button {
    background: transparent;
    padding: 20px;
    border-radius: 0;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .cancel {
    background: $error;
  }

  .refund {
    background: $warning;
  }

  .complete {
    background: $success;
  }

  .print {
    background: $primary-color;
  }

  .go-back {
    background: $cloud;
    margin-right: 10px;
    color: $secondary-font-color;
  }

  .booking-actions {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 5px;
  }

  .checkout-info {
    flex: 1;
    display: flex;
    margin-bottom: 10px;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .body {
      padding: 5px;
    }
  }


  .booking-row {
    display: flex;
    .customer-details {
      flex: 1;
    }

    .status-actions {
      flex: 1 1;

      h4 {
        width: 100%;
        text-transform: capitalize;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2rem;
        font-weight: 700;
      }

      .approved {
        color: $success;
      }

      .completed {
        color: $success;
      }

      .refunded {
        color: $warning;
      }

      .cancelled {
        color: $error;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      button {
        margin: 5px 4px;
      }
    }
  }

  .notes {
    margin-top: 15px;
  }

  .products-wrapper {
    .product {
      .product-header {
        font-weight: 800;
        display: flex;
        strong {
          display: block;
          width: 80%;
        }
      }

      .tiers {
        .tier {
          padding: 1px 17px;
          .size {
            display: flex;
            width: 100%;
          }

          .left {
            display: flex;
            width: 80%;
          }

          .right {
            width: 15px;
          }

          .extras-text {
          }

          .basket-title {
            font-weight: bold;
          }

          .addons {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            .left {
              width: 100%;
            }

            .addon {
              display: flex;
              background: $cloud;
              margin: 3px 10px;
              padding: 0 5px;
            }
          }
        }
      }
    }
  }

  .booking-summary {
    margin-top: 10px;

    .summary-item {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-size: 1.3rem;
        font-weight: bold;
      }

      .value {
      }
    }
  }
}
