#discounts {
  .selected-discount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 5px;

    p {
      margin: 0;
      color: $secondary-font-color;
    }
    h4 {
      margin: 0;
      strong {
        border: 1px solid $gray;
        padding: 10px;
      }
    }
    i {
      font-size: 2rem;
    }
    .remove {
      background: $danger;
      color: $primary-font-color;
      border: none;
      margin-left: 10px;
      padding: 10px;
    }
  }

  .create-discount {
    .discount-types {
      display: flex;

      .type {
        background-color: $secondary-color;
        color: $primary-font-color;
        font-size: 1.3rem;
        font-weight: 600;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;
      }

      .active {
        background: $success;
      }
    }
    .create-discount-btn {
      background: #3f9c35;
      color: #ffffff;
      width: 65%;
      border: none;
      outline: none;
      padding: 15px;
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: 6px;
      margin-top: 10px;
    }
  }
}
