.customer-container {
  flex: 1 1;
  background: white;
  margin: 0 7px;
  box-sizing: border-box;
  width: 70%;
  overflow-y: scroll;

  .address-section {
    height: 70%;
    overflow-y: scroll;
  }

  .notes-section {
    margin-top: 5px;

    h5 {
      width: 100%;
      font-size: 1rem;
    }

    .customer-notes {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      .note {
        border: 1px solid black;
        padding: 5px;
        font-size: 1.3rem;
        margin: 6px;

        span {
          display: block;
          font-size: 1.2rem;
        }
      }
    }
  }

  label {
    margin: 0;
    font-size: 0.85rem;
  }

  .text-input {
    width: 100%;
    height: 48px;
  }

  .postcode-finder {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      height: 40px;
      font-size: 0.7rem;
    }
  }

  .customer-actions {
    padding-left: 15px;

    .customer-btn {
      background: $success;
      color: $primary-font-color;
      width: 65%;
      border: none;
      outline: none;
      padding: 15px;
      font-size: 0.8rem;
      font-weight: 600;
      margin-right: 6px;
      height: 50px;
    }

    .clear-btn {
      background: $coal;
      color: $primary-font-color;
      width: 30%;
      border: none;
      outline: none;
      padding: 15px 10px;
      font-size: 0.8rem;
      font-weight: 600;
      margin-right: 6px;
      height: 50px;
    }
  }

  .search-postcode {
    display: flex;
    width: 100px;
    height: 40px;
    justify-content: center;
    padding: 0 5px;
    align-items: center;
    margin-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .mobile-prefix {
    font-size: 1.5rem;
    color: black;
    font-weight: 900;
  }
}


.addresses {
  padding: 10px 10px 0 0;
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;

  h3 {
    width: 100%;
    font-size: 1rem;
  }

  .address {
    border: 1px solid gray;
    margin: 5px 0 5px 5px;
    padding: 10px 5px;
    width: 100%;

    h4 {
      font-size: 0.8rem;
      font-weight: 500;
      margin: 0;
    }
  }

  .selected {
    border: 4px solid $primary-bg;
  }
}
