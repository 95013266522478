.home {

  .line-scale > div {
    background-color: $primary-bg;
  }
  .disabled {
    color: #ffffff;
    text-shadow: 1px 1px 5px #cecece;
  }

  button, a {
    border: 3px solid $primary-font-color;
    background: $primary-bg;
    color: $primary-font-color;
    height: 70px;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  button:disabled {
    background: rgba($primary-bg, 0.5);
  }
  a {
    background: $primary-bg;
  }

  .disabled {
    background: rgba($primary-bg, 0.5);
  }
  .actions {
    height: 85vh;
    margin: 10px 0 0 0;

    .actions-disabled {
      background: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: $secondary-font-color;
        font-size: 1.4rem;
        font-weight: 900;
      }
    }

    .col {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 10px 0;
      height: fit-content;
    }
    .timestamp {
      width: 99%;
      text-align: center;
      background: $info;
      font-weight: bolder;
      font-size: 1.3rem;
      padding: 5px 0;
      margin: 0 3px;
      color: $primary-font-color;
    }

    .w-33 {
      width: 33.33%;
    }

    .w-66 {
      width: 66.66%;
    }
  }

  .branch-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 5px;
    height: 200px;
    h2 {
      font-weight: 900;
      margin-bottom: 20px;
    }
    h3 {
      width: 100%;
      font-size: 1.5rem;
    }

    .user-name {
      width: 100%;
      text-align: center;
    }
  }

  .footer {
    padding: 5px 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    button, a {
      flex: 1;
    }
  }
}
