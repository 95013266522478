.drivers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85vh;
  flex: 1 1;
  padding: 20px;
  width: 100%;

  .title {
    font-weight: 800;
    margin-bottom: 20px;
  }

  button {
    border: none;
    background: $secondary-color;
    color: $primary-font-color;
    width: 315px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
  }


  .numpad-wrapper {
    width: 320px;
    height: 382px;
    position: relative;
    margin-bottom: 10px;
    margin-top: 5px;

    button {
      background: $cloud;
    }

    .numpad {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .numpad-display {
        font-family: monospace;
        color: #715e94;
        background: white;
        line-height: 80px;
        font-size: 4em;
        height: 61px;
        text-align: center;
        width: 97%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #7700b3;
        font-weight: bold;
        letter-spacing: -5px;
        border-radius: 5px;

        .selected {
          background: #cb87ec;
          padding: 5px 15px 2px;
          font-size: 2.8rem;
        }
      }


      .auto-scaling-text {

      }

      .numpad-keypad {
        height: 326px;
        display: flex;
      }

      .input-keys {
        width: 240px;
      }

      .function-keys {
        display: flex;

        .numpad-key {
          font-size: 2em;
          font-family: monospace;
          font-weight: 600;
        }

        .key-multiply {
          line-height: 50px;
        }
      }

      .digit-keys {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;

        .numpad-key {
          font-size: 2.25em;
          font-family: monospace;
          font-weight: 600;
          color: #515156 !important
        }

        .key-0 {
          width: 152px;
          text-align: left;
          padding-left: 32px;
        }

        .key-dot {
          padding-top: 1em;
          font-size: 0.75em;
        }
      }

      .numpad-key {
        width: 73px;
        height: 75px;
        text-align: center;
        line-height: 80px;
        color: white;
        font-size: 30px;
        font-weight: 600;

        .numpad-key {
          border-right: 0;
          font-size: 3em;
          font-family: monospace;
          font-weight: 600;
          color: black !important;
        }
      }

      .function-keys {
        background: linear-gradient(to bottom, rgba(202, 202, 204, 1) 0%, rgba(196, 194, 204, 1) 100%);
      }

      .operator-keys {
        display: flex;
        flex-direction: column;

        button {
          background: #777;
        }
      }

      button {
        margin: 3px;
        display: block;
        border: none;
        border-radius: 5px;
        padding: 0;
        font-family: inherit;
        user-select: none;
        cursor: pointer;
        outline: none;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }

      button:active {
        box-shadow: inset 0px 0px 80px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }


  .numeric-input {
    background: #fefdff;
    color: black;
    width: 70px;
    height: 60px;
    border-radius: 0;
    font-size: 30px;
    font-weight: 500;
    border: 1px solid #f1f1f1;
    margin-bottom: 15px;
  }

  .numeric-input-2 {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    background: #fefdff;
    color: black;
    height: 60px;
    border-radius: 0px;
    border: 1px solid #f1f1f1;
    margin-bottom: 15px;
  }

  .input-signin {
    border: 0px;
    color: white;
    width: 100%;
    height: 60px;
    border-radius: 0px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    background: $primary-bg;
    border: 3px solid #e5e5e5;
  }

  .input-signin:focus {
    background: $primary-bg;
  }

  .input-signin:active {
    background: $primary-bg;
  }

  .input-signin:hover {
    color: white;
    background: $primary-bg;
    border: 3px solid #e5e5e5;
  }

  .driver-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;


    .clock-in-btn {
      background: $success;
    }

    .clock-out-btn {
      background: $secondary-color;
    }

    .online {
      background: $primary-bg;
      color: $primary-font-color;
    }

    a {
      background: rgba($primary-bg, 0.5);
      padding: 0 10px;
      margin: 5px;
    }
  }
}
