.notes {
  padding: 0;

  .add-notes-btn {
    background: $primary-bg;
    color: $primary-font-color;
    margin-top: 20px;
    border: none;
    outline: none;
    width: 40%;
    padding: 20px 0;
    font-size: 1.7rem;
    font-weight: 700;
  }

  input {
    border-radius: 0;

  }
  .row {
    margin: 0;
  }
  .add-notes {
    button {
      margin-top: 20px;
      height: 48px;
    }
    .col-md-10 {
      padding: 0;
    }
    .col-md-2 {
      padding: 0;
    }
  }

  .selected-notes {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }

  .selected-note {
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: max-content;
    .value {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: $cloud;
      padding: 0 10px;
          border: 1px solid #8800cc;
    }

    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: $primary-bg;
      width: 50px;
      font-weight: bold;
      font-size: 1.7rem;
      color: white;
    }
  }
  .manage-notes {
    flex-direction: column;
    margin-top: 10px;
    padding: 0 5px;

    button {
      margin-top: 20px;
      height: 48px;
    }
    .col-md-10 {
      padding: 0;
    }
    .col-md-2 {
      padding: 0;
    }
  }

  .selected-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
}
