// body {
//   background: #f9f7fd;
// }

.pin-input {
  background: #fefdff;
  color: black;
  height: 60px;
  border-radius: 0;
  font-size: 30px;
  font-weight: 500;
  border: 1px solid $cloud;
}
.main-div {
  margin-top: 20px;
}

.main-title {
  color: $primary-bg;
  font-weight: bold;
  font-size: 50px;
  text-transform: uppercase;
}

.small-title {
  color: white;
}

.auth-inner {
  width: 100%;
  margin: auto;
}

.numeric-input {
  background: #fefdff;
  color: black;
  width: 70px;
  height: 60px;
  border-radius: 0;
  font-size: 30px;
  font-weight: 500;
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
}

.input-class {
  height: 50px;
  border-radius: 0;
  width: 100%;
  border: none;
  padding: 0 10px;
  font-size: 1rem;
}

.numeric-input-2 {
  width: 100%;
  font-size: 30px;
  font-weight: 500;
  background: #fefdff;
  color: black;
  height: 60px;
  border-radius: 0px;
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
}

.input-signin {
  border: 0px;
  color: white;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  background: $primary-bg;
  border: 3px solid #e5e5e5;
}
.input-signin:focus {
  background: $primary-bg;
}
.input-signin:active {
  background: $primary-bg;
}
.input-signin:hover {
  color: white;
  background: $primary-bg;
  border: 3px solid #e5e5e5;
}

.num {
  width: 270px;
  margin: auto;
}
.login-bottom {
  color: white;
}

.login-bottom > a {
  color: #61b4f6;
}

.bottom {
  color: white;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bottomRight {
  background: #007bff;
  padding: 0px 5px 0px 5px;
  color: white;
  position: fixed;
  bottom: 0;
  right: 10px;
}

@media (max-width: 767px) {
  .auth-inner {
    width: 350px;
    margin: auto;
  }
}
