.edit-product {

  .prod-list {
    margin-right: 10px;
  }
  .prod-price {
    color: $primary-bg;
  }
  .prod-title {
    color: $secondary-font-color;
  }

  .t-selected {
    .prod-price {
      color: $primary-font-color;
    }
    .prod-title > h6 {
      color: $primary-font-color;
    }
  }

  .clickables {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 5px;

    .clickable {
      border: none;
      display: flex;
      background: $primary-bg;
      margin: 10px;
      padding: 0;
      height: 60px;
      align-items: center;

      button {
        border: none;
        outline: none;
        background: transparent;
        color: $primary-font-color;
        padding: 0 10px;
        font-size: 1.5rem;
        font-weight: 600;
      }

      a {
        background: $error;
        color: $primary-font-color;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
      }
    }
  }

}
