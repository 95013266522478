body {
  font-family: $primary-font;
  margin: 0;
}
.bg-dark {
    background-color: $primary-bg !important;
}
.btn-danger {
  background-color: $error !important;
  box-shadow: 1px 3px 4px -2px rgba(239, 63, 62, 1);
}
.btn-info {
  background-color: #00a2f7 !important;
  box-shadow: 1px 3px 4px -2px #00a2f7;
}
.text-secondary {
  color: #7700b3 !important;
}
.box-shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.border{
  border: 1px solid #8800cc;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
