.customers {
  width: 100%;
  padding: 0;

  .search-bookings {
    margin-bottom: 5px;

    input {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }

    .btn-refresh {
      background: $coal;
      width: 100px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .customer {
    margin: 10px 0;
    border: 1px solid $primary-bg;
    padding: 5px;

    .user-info {
      display: flex;
      justify-content: space-between;
      height: 70px;
      align-items: center;
      .email {
        padding-left: 5px;
      }
      .add-notes {
        background: $primary-bg;
        color: $primary-font-color;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        margin-right: 5px;
        font-size: 1.3rem;
        font-weight: 600;
      }
    }

    .addresses {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
      .address {
        border: 1px solid $gray;
        margin: 5px 0 5px 5px;
        padding: 10px 5px;
        width: auto;
        .name {
        }

        .telephone {
        }

        .location {
        }
      }
    }
  }
}
