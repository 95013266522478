.management {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85vh;
  flex: 1;
  padding: 20px;

  .panel-btn {
    background: $secondary-bg;
    color: $primary-font-color;
    min-width: 200px;
    height: 50px;
    display: flex;
    font-size: 1.4rem;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
  }

  .row {
    width: 100%;
  }

  .title {
    font-weight: 800;
    margin-bottom: 20px;
  }

  p {
    font-size: 2rem;
    font-weight: 900;
  }

  .add-printer-btn {
    background: $primary-bg;
    color: $primary-font-color;
    margin: 2px 3px;
  }

  .printer-settings {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 70vh;

    .add-printer {
      border: 2px solid $secondary-color;
      width: 330px;
      margin: 10px;

      input[type=checkbox] {
        transform: scale(2);
      }

      .add-printer-btn {
        background: $primary-bg;
        color: $primary-font-color;
        width: 98%;
        margin: 2px 3px;
        padding: 0;
        height: 46px;
        font-weight: 700;
        font-size: 1.2rem;
      }

      .form-check {
        height: 35px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 35px;
        input {
          margin-left: -24px;
        }
      }

      .printer-type {
        display: flex;

        .selected {
          background: $secondary-color;
          color: $primary-font-color;
        }
        button {
          flex: 1 1;
          margin: 2px;
          outline: none;
        }
      }

      .network {
        display: flex;

        .ip-address {
          width: 65%;
        }
        .port {
          width: 35%;
        }
      }

      .no-of-prints {
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
          width: 65%;
          margin: 0;
          padding-left: 5px;
        }
        input {
          width: 35%;
        }
      }

      input {
      }

      button {
        border: none;
        padding: 10px;
        outline: none;
      }

      .categories-selector {
        padding: 0 1px;
        margin: 3px 0;
        button {
          border: none;
          padding: 8px;
          margin: 2px;
          outline: none;
        }
        .category-selected {
          background: $secondary-color;
          color: $primary-font-color;
        }
      }
    }
  }
}
