.tasks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85vh;
  flex: 1;
  padding: 20px;

  .cancel-action {
    position: fixed;
    font-size: 4rem;
    right: 35px;
  }

  .task-drivers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    width: 100%;
    position: relative;
    padding-top: 5px;
    border: 4px solid $success;
    margin-bottom: 7px;
    min-height: 88px;
    overflow-x: scroll;

    button {
      border: none;
      color: $primary-font-color;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 600;
      background: rgba($primary-bg, 0.5);
      padding: 0 10px;
      margin: 5px;
      width: 100%;
      min-width: fit-content;
    }


    .online {
      background: $primary-bg;
      color: $primary-font-color;
    }

    .numpad-wrapper {
      width: 320px;
      height: 382px;
      position: relative;
      margin-bottom: 10px;
      margin-top: 5px;

      button {
        background: $cloud;
      }

      .numpad {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .numpad-display {
          font-family: monospace;
          color: #715e94;
          background: white;
          line-height: 80px;
          font-size: 4em;
          height: 61px;
          text-align: center;
          width: 97%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #7700b3;
          font-weight: bold;
          letter-spacing: -5px;
          border-radius: 5px;

          .selected {
            background: #cb87ec;
            padding: 5px 15px 2px;
            font-size: 2.8rem;
          }
        }


        .auto-scaling-text {

        }

        .numpad-keypad {
          height: 326px;
          display: flex;
        }

        .input-keys {
          width: 240px;
        }

        .function-keys {
          display: flex;

          .numpad-key {
            font-size: 2em;
            font-family: monospace;
            font-weight: 600;
          }

          .key-multiply {
            line-height: 50px;
          }
        }

        .digit-keys {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap-reverse;

          .numpad-key {
            font-size: 2.25em;
            font-family: monospace;
            font-weight: 600;
            color: #515156 !important
          }

          .key-0 {
            width: 152px;
            text-align: left;
            padding-left: 32px;
          }

          .key-dot {
            padding-top: 1em;
            font-size: 0.75em;
          }
        }

        .numpad-key {
          width: 73px;
          height: 75px;
          text-align: center;
          line-height: 80px;
          color: white;
          font-size: 30px;
          font-weight: 600;

          .numpad-key {
            border-right: 0;
            font-size: 3em;
            font-family: monospace;
            font-weight: 600;
            color: black !important;
          }
        }

        .function-keys {
          background: linear-gradient(to bottom, rgba(202, 202, 204, 1) 0%, rgba(196, 194, 204, 1) 100%);
        }

        .operator-keys {
          display: flex;
          flex-direction: column;

          button {
            background: #777;
          }
        }

        button {
          margin: 3px;
          display: block;
          border: none;
          border-radius: 5px;
          padding: 0;
          font-family: inherit;
          user-select: none;
          cursor: pointer;
          outline: none;

          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        button:active {
          box-shadow: inset 0px 0px 80px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }


    .numeric-input {
      background: #fefdff;
      color: black;
      width: 70px;
      height: 60px;
      border-radius: 0;
      font-size: 30px;
      font-weight: 500;
      border: 1px solid #f1f1f1;
      margin-bottom: 15px;
    }

    .numeric-input-2 {
      width: 100%;
      font-size: 30px;
      font-weight: 500;
      background: #fefdff;
      color: black;
      height: 60px;
      border-radius: 0px;
      border: 1px solid #f1f1f1;
      margin-bottom: 15px;
    }

    .input-signin {
      border: 0px;
      color: white;
      width: 100%;
      height: 60px;
      border-radius: 0px;
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
      background: $primary-bg;
      border: 3px solid #e5e5e5;
    }

    .input-signin:focus {
      background: $primary-bg;
    }

    .input-signin:active {
      background: $primary-bg;
    }

    .input-signin:hover {
      color: white;
      background: $primary-bg;
      border: 3px solid #e5e5e5;
    }

    .driver-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px;
      width: 100%;


      .clock-in-btn {
        background: $success;
      }

      .clock-out-btn {
        background: $secondary-color;
      }

      .online {
        background: $primary-bg;
        color: $primary-font-color;
      }

      a {
        background: rgba($primary-bg, 0.5);
        padding: 0 10px;
        margin: 5px;
      }
    }
  }

  .title {
    font-weight: 800;
    margin-bottom: 20px;
  }

  .row {
    width: 100%;
  }

  .filter {
    display: flex;
    margin-bottom: 15px;
    width: 100%;

    .left {
      display: flex;
      flex: 2;
      justify-content: flex-start;

      .vertical-divider {
        border: 2px solid black;
      }

      button {
        background: $secondary-color;
      }

      .active {
        background: $success;
      }
    }
    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      button {
        background: $primary-bg;
        color: $primary-font-color;
        border: none;
        margin: 5px;
        min-width: 200px;
        height: 55px;
        font-size: 1.5rem;
        font-weight: 600;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        background: $primary-bg;
        color: $primary-font-color;
        border: none;
        margin: 5px;
        min-width: 200px;
        height: 55px;
        font-size: 1.5rem;
        font-weight: 600;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }

  }

  .driver-name {
    background: $info;
    color: $primary-font-color;
    text-align: center;
    font-size: 1.5rem;
    padding: 5px 0;
  }
  button {
    color: $primary-font-color;
    border: none;
    margin: 5px;
    min-width: 22%;
    height: 55px;
    font-size: 1.1rem;
    font-weight: 600;
    outline: none;

  }

  .create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .create-task {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    form {
      width: 60%;
      padding: 0 10px 0 0;

      .task-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 0 0 0;
        button {
          background: $info;
        }
      }

      .checkout-mode {
        color: $secondary-font-color;
        padding: 0 7px;
        font-weight: 900;
        font-size: 1.25rem;
      }

      .selected {
        background: $secondary-color;
        color: $primary-font-color;
      }

      .form-group {

        button {
          min-width: auto;
          color: $secondary-font-color;
          font-size: 1rem;
          font-weight: normal;
        }
        .search-postcode {
          color: $primary-font-color;
          width: 150px;
        }
      }
    }
  }

  .drivers {
    button {
      background: rgba($primary-bg, 0.5);
    }

    .online {
      background: $primary-bg;
      color: $primary-font-color;
      font-weight: 700;
      font-size: 1.2rem;
    }
  }

  .vendors {
    width: 100%;

    button {
      background: $primary-bg;
    }
  }

  .tasks-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 0;

    .job-actions {
      position: relative;
      padding-top: 5px;
      display: flex;
      border: 4px solid $success;
      margin-bottom: 7px;
      min-height: 88px;
      overflow-x: auto;
      padding-right: 165px;

      .complete {
        background-color: $success;
      }
      .refund {
        background-color: $warning;
      }

      .incomplete {
        background: $secondary-color;
      }

      .cancel {
        background-color: $error;
      }
      .edit {
        background-color: $cloud;
        border: 1px solid $coal;
        margin: 5px;
        min-width: 22%;
        height: 55px;
        font-size: 1.1rem;
        font-weight: 600;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      width: 100%;
      overflow-y: scroll;

      .task-loader {
        display: flex;
        height: 50vh;
        justify-content: center;
        align-items: center;
      }

      li {
        .booking {
          position: relative;
          display: flex;
          border: 4px solid $success;
          margin-bottom: 7px;

          .booking-status {
            position: absolute;
            background: gray;
            color: white;
            top: 0;
            left: 0;
            width: 120px;
            height: 25px;
            line-height: 18px;
            padding: 0 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom-right-radius: 30px;
            font-weight: 700;
            font-size: 1.3rem;
          }

          .synced {
            background: $success;
          }

          // .offline {}

          .booking-info {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            width: 45%;

            .platform {
              display: flex;
              font-size: 1rem;
              font-weight: 600;
              padding: 0;
              width: 20%;
              height: 55%;
              margin-left: 5px;

              span {
                font-size: 0.75rem;
                background: #00a2f7;
                padding: 0 5px;
                height: 100%;
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
                font-weight: 800;
                color: white;
                text-transform: uppercase;
              }

            }

            .address-details {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 80%;
              text-align: center;

              .address {
                color: $coal;
                font-size: 0.9rem;
                font-weight: 600;
                width: 80%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .postcode {
                font-size: 1.5rem;
                font-weight: 900;
              }

              .order-id {
                font-size: 0.8rem;
                font-weight: 600;
                color: $gray;
              }
            }
          }

          .payment-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 15%;
            font-size: 0.9rem;
            font-weight: 700;

            .amount {
              margin-right: 10px;
            }

            .status {

            }
          }

          .time-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20%;

            .status {
              font-size: 0.85rem;
              margin: 0;
              .takeaway{
                font-size: 1.5rem;
                color: $error;
                font-weight: 900;
                text-transform: uppercase;
                font-family: $primary-font;
              }
              .delivery{
                font-size: 1.5rem;
                color: $warning;
                font-weight: 900;
                text-transform: uppercase;
                font-family: $primary-font;
              }
            }

            .value {
              font-size: 0.9rem;
              font-weight: 900;
              color: $success;
              margin: 0;
            }
          }

          .payment-method {
            display: flex;
            justify-content: center;
            width: 30%;
            align-items: flex-end;
            margin-right: 5px;
            background: transparent;
            padding-bottom: 5px;
            flex-direction: column;

            .assign-buttons {
              display: flex;
            }

            .complete {
              background: $success;
              text-transform: uppercase;
            }

            .assigned-wrapper {
              display: flex;
              flex-direction: column;
              width: 95%;
            }

            .assigned-driver {
              color: $secondary-font-color;
              width: 100%;
              margin: 0;
              text-align: center;
              font-size: 1rem;
              font-weight: 600;
            }

            button {
              border: none;
              font-weight: 600;
              text-align: center;
              font-size: 1rem;
              margin: 0;
              color: $primary-font-color;
              font-family: $primary-font;
              padding: 15px;
              width: 90%;
            }
            .assign {
              background: $secondary-color;
            }
            .update {
              background: $warning;
            }

            .assigned {
              background: $success;
            }
          }
        }
      }
    }
  }
}
