.sales {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85vh;
  flex: 1 1;
  width: 100%;
  padding-top: 25px;

  .row {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .show-details {
    background: $coal;
    color: $primary-font-color;
    height: 50px;
    font-size: 1rem;
    width: 135px;
    margin-bottom: 10px;
  }

  .title {
    font-weight: 800;
    margin-bottom: 20px;
  }

  .header {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .print-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .report {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    padding-top: 15px;

    table {
      margin: 0 auto;
      th, td {
        border: 1px solid $gray;
        padding: 10px;
        text-align: center;
      }
      td {
        font-weight: 500;
      }
    }
  }
  button {
    border: none;
    background: $secondary-color;
    color: $primary-font-color;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: 600;
  }
}
