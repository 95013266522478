:root {
  --primary-color: #90011E;
  --secondary-color: #268240;
  --text-color: white;
  --background-color: #000000; /* Change this to your desired background color */
  --floor-color: #7700b3;
}

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

body {
  font-family: 'Poppins', sans-serif;
}

/* Use the custom properties in your styles */
.dine-in {
  display: flex;
  width: 100%;
  padding: 20px;
}

.dine-in-page {
  text-align: center;
  display: flex;
  flex: 5;
  flex-direction: column;
  width: 100%;
}

.floor-selector {
  margin-bottom: 10px;
  width: 100%;
  display: flex;

  button {
    margin: 0px 5px;
    cursor: pointer;
    border: none;
    background-color: var(--primary-color);
    color: var(--text-color);
    border-radius: 5px;
    width: 100%;
    height: 70px;
    font-size: 1.5rem;
    font-weight: bolder;

    &:hover {
      background-color: var(--secondary-color);
    }

    &.floor {
      background-color: var(--floor-color);
      color: white;
      font-size: 1.5rem;
      font-weight: bolder;
      border: 1px solid black;
    }

    &.selected-floor {
      background-color: var(--text-color);
      color: var(--primary-color);
      font-size: 1.5rem;
      font-weight: bolder;
      border: 1px solid black;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  place-items: center;

  .box {
    width: 100%;
    height: 70px;
    color: var(--text-color);
    border: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bolder;
    background-color: var(--primary-color);
    flex-direction: column;
  }

  p {
    margin: 0%;
    color: var(--text-color);
    font-size: small;
  }

  .selected {
    background-color: var(--secondary-color);
  }
}

.dine-btn {
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  flex: 1;
  padding-left: 20px;
}

.head {
  height: 100px;
  margin-bottom: 10px;
}

.back-button {
  text-align: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 1rem;
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    background-color: var(--secondary-color);
  }
}

.Manage {
  height: 80px !important;
}
