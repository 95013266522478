.members {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 85vh;
  flex: 1 1;
  padding: 20px;


  .row {
    width: 100%;
  }

  .title {
    font-weight: 800;
    margin-bottom: 20px;
  }

  .timestamp {
    width: 99%;
    text-align: center;
    background: $info;
    font-weight: bolder;
    font-size: 1.3rem;
    padding: 5px 0;
    margin: 0 3px;
    color: $primary-font-color;
  }
  button, a {
    border: 3px solid $primary-font-color;
    background: $primary-bg;
    color: $primary-font-color;
    height: 80px;
    padding: 0;
    font-size: 1.5rem;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 32%;
  }
  button:disabled {
    background: rgba($primary-bg, 0.5);
  }
  a {
    background: $primary-bg;
  }

  .disabled {
    background: rgba($primary-bg, 0.5);
  }


  .numeric-input {
    background: #fefdff;
    color: black;
    width: 70px;
    height: 60px;
    border-radius: 0;
    font-size: 30px;
    font-weight: 500;
    border: 1px solid #f1f1f1;
    margin-bottom: 15px;
  }

  .numeric-input-2 {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    background: #fefdff;
    color: black;
    height: 60px;
    border-radius: 0px;
    border: 1px solid #f1f1f1;
    margin-bottom: 15px;
  }

  .input-signin {
    border: 0px;
    color: white;
    width: 100%;
    height: 60px;
    border-radius: 0px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    background: $primary-bg;
    border: 3px solid #e5e5e5;
  }
  .input-signin:focus {
    background: $primary-bg;
  }
  .input-signin:active {
    background: $primary-bg;
  }
  .input-signin:hover {
    color: white;
    background: $primary-bg;
    border: 3px solid #e5e5e5;
  }

  .clock-in {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 85vh;
    flex: 1;
    padding: 20px;
    width: 100%;
  }
}
