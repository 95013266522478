.customer-wrapper {

  display: flex;
  width: 100%;
  align-items: stretch;
  height: 100vh;

  .cart {
    padding: 0;

    #sidebar {

      .cart-items {
        height: 77%;
        overflow: hidden;
      }

      .cart-content {
        width: 100%;

        .tree-view {
          margin-left: 0;
        }
      }
    }
  }

  .view-container {
    background: white;
    margin: 0 7px;
    box-sizing: border-box;
    width: 100%;
    padding: 6px 20px;
    height: 100%;
    overflow-y: scroll;

  }

}
