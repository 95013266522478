.bookings {
  width: 100%;
  padding: 0;

  .search-bookings {
    margin-bottom: 5px;
    input {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }
    .btn-refresh {
      background: $coal;
      width: 100px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .offline{
    border: 4px solid #aaaaaa !important;
  }
  .filter-bookings {
    margin-bottom: 5px;
    button {
      padding: 15px 40px;
      border-radius: 0;
      border: none;
    }
    .selected {
      border: 3px solid $primary-bg;
    }
  }

  .booking {
    position: relative;
    padding-top: 5px;
    display: flex;
    border: 4px solid $success;
    margin-bottom: 7px;

    .booking-status {
      position: absolute;
      background: gray;
      color: white;
      top: 0;
      left: 0;
      width: 120px;
      height: 25px;
      line-height: 18px;
      padding: 0 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom-right-radius: 30px;
      font-weight: 700;
      font-size: 1.3rem;
    }

    .synced {
      background: $success;
    }

    // .offline {}

    .booking-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 50%;

      .platform {
        display: flex;
        font-size: 1rem;
        font-weight: 600;
        padding: 0;
        width: 20%;
        height: 55%;
        margin-left: 5px;

        span {
          font-size: 0.75rem;
          background: #00a2f7;
          padding: 0 5px;
          height: 100%;
          width: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
          font-weight: 800;
          color: white;
          text-transform: uppercase;
        }

      }

      .address-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        text-align: center;

        .address {
          color: $coal;
          font-size: 0.9rem;
          font-weight: 600;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .postcode {
          font-size: 1.5rem;
          font-weight: 900;
        }

        .order-id {
          font-size: 0.8rem;
          font-weight: 600;
          color: $gray;
        }
      }
    }

    .payment-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 10%;
      font-size: 0.9rem;
      font-weight: 700;

      .amount {
        margin-right: 10px;
      }

      .status {

      }
    }

    .time-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 22%;

      .status {
        font-size: 0.85rem;
        margin: 0;
          .takeaway{
            font-size: 1.5rem;
            color: #d61a0d;
            font-weight: 900;
            text-transform: uppercase;
            font-family: $primary-font;
          }
          .delivery{
            font-size: 1.5rem;
            color: #ffc107;
            font-weight: 900;
            text-transform: uppercase;
            font-family: $primary-font;
          }
      }

      .value {
        font-size: 0.9rem;
        font-weight: 900;
        color: #3f9c35;
      }
    }

    .payment-method {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 17%;
      align-items: flex-end;
      margin-right: 5px;
      background: transparent;
      border: none;

      p {
        font-weight: 600;
        padding: 15px 5px;
        width: 120px;
        text-align: center;
        font-size: 1.4rem;
        margin-right: 1px;
        background: #10106a;
        color: #e5e5e5;
        margin-bottom: 0;
        font-family: $primary-font;
      }
    }
  }
}
